/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on March 20, 2024 */



@font-face {
    font-family: 'khand';
    src: url('Khand-Bold.ttf') format('truetype'),
         url('Khand-Light.ttf') format('truetype'),
         url('Khand-Medium.ttf') format('truetype'),
         url('Khand-Regular.ttf') format('truetype'),
         url('Khand-SemiBold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}